import { axiosGet, axiosPost } from "./ajaxServices";
import { urlCheckEmailExist, urlLogin, urlLogout, urlRegister, urlSubscribeEmail } from "./constants";

export const apiLogin = async (userData) => {
  const url = urlLogin;
  var formData = new FormData();
  formData.append("email", userData.email);
  formData.append("password", userData.password);
  const response = await axiosPost(url, formData)
  return response
}

export const apiLogout = async (token) => {
  const url = urlLogout;
  const get_params = { token: token }
  const response = await axiosGet(url, get_params)
  return response
}

export const apiSubscribeEmail = async (values) => {
  const url = urlSubscribeEmail;
  var formData = new FormData();
  // for(let k in values) {
  //   formData.append(k, values[k]);
  // }
  formData.append("email", values["email"]);
  const response = await axiosPost(url, formData)
  return response
}

export const apiCheckEmailExist = async (values) => {
  const url = urlCheckEmailExist;
  var formData = new FormData();
  // for(let k in values) {
  //   formData.append(k, values[k]);
  // }
  formData.append("email", values["email"]);
  const response = await axiosPost(url, formData)
  return response
}

export const apiRegister = async (values) => {
  const url = urlRegister;
  var formData = new FormData();
  for(let k in values) {
    formData.append(k, values[k]);
  }
  const response = await axiosPost(url, formData)
  return response
}
