import axios from "axios";
import { BASE_APP_URL, BASE_FRONT_URL } from "../config/constants";
const URL_LOGIN = BASE_APP_URL + "/login"

const redirectToLoginPage = async (currentHref) => {
  if (currentHref !== URL_LOGIN) {
    window.location.href = URL_LOGIN
  } else {
    console.log("current pge is login page")
  }
  return false
}

export const axiosPost = async (url, param, errorMessage = "") => {
  try {
    const { data } = await axios.post(url, param)
    if (data.data && data.data.login_required === "1") {
      return await redirectToLoginPage(window.location.href)
    } else {
      return data
    }
  } catch (error) {
    console.error(errorMessage, error);
    return false
  }
}

export const axiosPostMultipart = async (url, param, errorMessage = "") => {
  try {
    const { data } = await axios.post(url, param, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    if (data.data && data.data.login_required === "1") {
      return await redirectToLoginPage(window.location.href)
    } else {
      return data
    }
  } catch (error) {
    console.error(errorMessage, error);
    return false
  }
}

export const axiosPut = async (url, param, errorMessage = "") => {
  try {
    const { data } = await axios.put(url, param)
    if (data.data && data.data.login_required === "1") {
      return await redirectToLoginPage(window.location.href)
    } else {
      return data
    }
  } catch (error) {
    console.error(errorMessage, error);
    return false
  }
}

export const axiosGet = async (url, param, errorMessage = "") => {
  try {
    const { data } = await axios.get(url, { params: param })
    if (data.data && data.data.login_required === "1") {
      return await redirectToLoginPage(window.location.href)
    } else {
      return data
    }
  } catch (error) {
    console.error(errorMessage, error);
    return false
  }
}

export const axiosDelete = async (url, param, errorMessage = "") => {
  try {
    const { data } = await axios.delete(url, { data: param })
    if (data.data && data.data.login_required === "1") {
      return await redirectToLoginPage(window.location.href)
    } else {
      return data
    }
  } catch (error) {
    console.error(errorMessage, error);
    return false
  }
}
