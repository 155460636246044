import React, { useEffect, useState } from "react";
import { apiSubscribeEmail } from "../services/authService";
import { console_log, empty } from "../utils/misc";
import { showToast } from "../utils/utils";

const Subscribe = () => {

  useEffect(() => {

  }, []);

  const [loading, setLoading] = useState(false)

  const defaultFromData = {
    email: "",
    submit: null
  }
  const [formData, setFormData] = useState(defaultFromData)

  const checkFormValidation = (form_data, current_field_name = "") => {
    let isValid = true
    if (empty(form_data['email'])) {
      isValid = false
    }
    return isValid
  }

  const onChangeFormField = (e, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = e.target.value
    setFormData(form_data)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = checkFormValidation({ ...formData })
    console_log("isValid:::", isValid)
    if (!isValid) {
      return false
    }
    onSubmitFormData(formData)
  }

  const onSubmitFormData = async (values) => {
    setLoading(true)
    const apiRes = await apiSubscribeEmail(values);
    console_log("apiRes::::", apiRes);
    if (apiRes['status'] === '1') {
      showToast(apiRes.message, 'success');
      setFormData(defaultFromData)
    } else {
      showToast(apiRes.message, 'error');
    }
    setLoading(false)
  }

  return (
    <section className="mailchimp-one" id="subscribe-box">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Subscribe to Our <span>Newsletter</span> <br /> to Get New Updates.
          </h2>
        </div>
        <form className="mailchimp-one__mc-form mc-form" onSubmit={onSubmit}>
          <label htmlFor="mc-email" className="sr-only">
            Add email
          </label>
          <input
            name="email"
            type="email"
            id="mc-email"
            className="formInput"
            placeholder="Email address"
            value={formData['email']}
            onChange={(e) => onChangeFormField(e, 'email')}
            required={true}
          />
          <button type="submit" disabled={loading}>
            <i className="dimon-icon-right-arrow"></i>
          </button>
        </form>
        <div className="mc-form__response"></div>
      </div>
    </section>
  );
};
export default Subscribe;
