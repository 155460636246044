import toast, { Toaster } from 'react-hot-toast';
import { empty } from './misc';

export const showToast = (msg, type="success") => { // type: success, error, info, warning
	if(empty(msg)) {
		return false
	}

	if(type === "success") {
		toast.success(msg);
	}
	else if(type === "error") {
		toast.error(msg);
	}
	else{
		toast(msg);
	}
};
